import React from 'react';
import { UniversalLink } from '@plone/volto/components';
import DataTable from 'react-data-table-component';
import ExportRow from '../ExportRow/ExportRow';
import LocalDate from '../LocalDate/LocalDate';

const columns = [
  {
    id: 'title',
    name: 'Ano',
    selector: (row) => (row.ano?.title ? row.ano.token : row.ano),
    format: (row, index) => (
      <span className={`relatorio ano`}>
        {row.ano ? row.ano.title : row.ano}
      </span>
    ),
    sortable: true,
  },
  {
    id: 'quadrimestre',
    name: 'Quadrimestre',
    selector: (row) => (row.quad ? row.quad.token : row.quadrimestre),
    format: (row, index) => (
      <span className={`relatorio quad`}>
        {row.quad ? row.quad.title : row.quadrimestre}
      </span>
    ),
    sortable: false,
  },
  {
    id: 'pdf',
    name: 'Arquivo',
    selector: (row) => row.arquivo_pdf,
    format: (row, index) => (
      <span className={`relatorio link`}>
        {row.arquivo_pdf && (
          <UniversalLink
            openLinkInNewTab={true}
            href={row.arquivo_pdf.download}
          >
            Download
          </UniversalLink>
        )}
      </span>
    ),
  },
  {
    id: 'veiculo',
    name: 'Veículo da publicação',
    selector: (row) => row.veiculo,
    format: (row, index) => (
      <span className={`relatorio veiculo`}>
        {row.veiculo ? row.veiculo.title : '-'}
      </span>
    ),
  },
  {
    id: 'data_da_publicacao',
    name: 'Data da publicação',
    selector: (row) => row.data_de_publicacao,
    format: (row, index) => (
      <span className={`relatorio data`}>
        {row.data_de_publicacao ? (
          <LocalDate date={row.data_de_publicacao} />
        ) : (
          '-'
        )}
      </span>
    ),
  },
  {
    id: 'pdf_publicacao',
    name: 'Arquivo da Publicação',
    selector: (row) => row.arquivo_pdf_publicacao,
    format: (row, index) => (
      <span className={`relatorio link`}>
        {row.arquivo_pdf_publicacao && (
          <UniversalLink
            openLinkInNewTab={true}
            href={row.arquivo_pdf_publicacao.download}
          >
            Download
          </UniversalLink>
        )}
      </span>
    ),
  },
];

const GestaoFiscal = ({ url, params, relatorios, allowExport }) => {
  return (
    relatorios && (
      <>
        <DataTable
          className={'gestao-items'}
          columns={columns}
          data={relatorios}
          dense
          striped
        />
        {allowExport && (
          <ExportRow
            url={url}
            params={params}
            endpoint={'@export-generico/gestao-fiscal'}
          />
        )}
      </>
    )
  );
};

export default GestaoFiscal;
